import React from "react";
import "./../css/components/not-found.scss";

export default class NotFound extends React.Component {
  render() {
    return (
      <div className="not-found">
        <img className="gif-container" src="/404.gif" alt="404 Page Not Found" />
      </div>
    );
  }
}
