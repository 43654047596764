import React from "react";
import "./../css/components/gif-tv.scss";
import shuffle from "shuffle-array";

export default class GifTv extends React.Component {
  constructor(props) {
    super(props);
    this.state = { video: "", preloadVideo: "", images: [], currentIndex: 0 };
  }

  componentDidMount() {
    let $this = this;
    document.addEventListener("keydown", this.keyPress, false);
    window.addEventListener(
      "videoError",
      function(e) {
        $this.videoEnd();
      },
      false
    );
    window.addEventListener(
      "videoEnd",
      function(e) {
        $this.videoEnd();
      },
      false
    );
    this.resetImages();
  }

  keyPress = (e) => {
    if (e.keyCode === 39) {
      if (this.state.images.length === this.state.currentIndex + 1) {
        this.resetImages();
      } else {
        let newIndex = this.state.currentIndex + 1;
        this.setState({currentIndex: newIndex}, () => this.loadImage());
      }
    } else if (e.keyCode === 37) {
      if (this.state.currentIndex !== 0) {
        let newIndex = this.state.currentIndex - 1;
        this.setState({currentIndex: newIndex}, () => this.loadImage());
      }
    }
  }

  resetImages = () => {
    fetch("/image_count.json")
      .then(response => {
        return response.json();
      })
      .then(data => {
        let imageCount = data.count;
        let videos = [];
        let videosWithIntros = [];
        let introCount = 1;
        for (var i = 1; i <= imageCount; i++) {
          videos.push("/images/" + i + ".mp4");
        }
        let shuffledVideos = shuffle(videos);
        for (var x = 0; x <= shuffledVideos.length; x++) {
          if (x === 0 || x % 20 === 0) {
            videosWithIntros.push("/images/intro-" + introCount + ".mp4");
            introCount = introCount !== 5? introCount + 1 : 0;
          }
          videosWithIntros.push(shuffledVideos[x]);
        }

        this.setState({ images: videosWithIntros, currentIndex: 0 }, () => this.loadImage());
      })
      .catch(err => {});
  };

  loadImage = () => {
    this.setState({ video: this.state.images[this.state.currentIndex], preloadVideo: this.state.images[this.state.currentIndex + 1] });
  };

  videoEnd = () => {
    let images = this.state.images;
    let $this = this;
    if (images.length === this.state.currentIndex + 1) {
      this.resetImages();
    } else {
      let newIndex = this.state.currentIndex + 1;
      this.setState({ images: images, currentIndex: newIndex }, () => $this.loadImage());
    }
  };

  render() {
    return (
      <div>
        <div className="gif-tv">
          {this.state.video !== '' &&
          <div
            dangerouslySetInnerHTML={{
              __html: `
				<video
				class='gif'
				onended="window.dispatchEvent(new Event('videoEnd'))"
        onerror="window.dispatchEvent(new Event('videoError'))"
				src="${this.state.video}"
				muted
				autoplay
				playsinline
				/>
				`
            }}
          />
          }
        </div>
      </div>
    );
  }
}
