import React from "react";
import { BrowserRouter, Route, Switch, } from 'react-router-dom';
import "./App.scss";
import GifTv from "./components/GifTv.js";
import NotFound from "./components/NotFound.js"
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="container">
      <BrowserRouter>
        <Switch>
          <Route path="/" component={GifTv} exact />
          <Route component={NotFound} alt="404 Page Not Found" />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App;
